import React from 'react';
import './Card.css';

const openURL = url => {
  window.open(url, '_blank');
};

const Card = ({ title, description, stack, demo, github, icon }) => {
  return (
    <div>
      <div className="myCard">
        <div className="card-content valign-wrapper">
          <div
            className="card-image"
            style={{
              backgroundImage: `url(${icon})`
            }}
          />
        </div>
        <div className="center-align">
          <div className="card-description">
            <p className="title">{title}</p>
            <blockquote style={{ textAlign: 'left' }}>{description}</blockquote>
          </div>
          <div className="card-footer">
            <div className="stack">
              {stack.map(image => (
                <img
                  src={`./images/${image}.png`}
                  style={{ width: '60px', height: '60px' }}
                  alt={image}
                />
              ))}
            </div>
            <button
              className="btn-small deep-purple darken-4"
              type="button"
              onClick={() => openURL(demo)}
              disabled={!demo.length}
            >
              <i className="material-icons left white-text">remove_red_eye</i>
              Demo
            </button>
            <button
              className="btn-small deep-purple darken-4"
              type="button"
              onClick={() => openURL(github)}
              disabled={!github.length}
            >
              <i className="material-icons left">remove_red_eye</i>Github
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
