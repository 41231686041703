import React from 'react';
import Logo from '../images/logo.png';
import Email from '../images/email_bold.png';

export const Header = () => {
  return (
    <div className="section no-pad-bot" id="index-banner">
      <div className="container rounded-corners">
        <br />
        <div className="center">
          <img src={Logo} alt="logo" />
        </div>
        <div className="row">
          <blockquote>
            Welcome to React Playground where I add applications and projects as
            and when I create them. If you want something in particular let me
            know and I will keep that in mind when I add more stuff to my
            playground. The focus for the below applications is on development
            so design has been kept simple.{' '}
            <p>
              A bit about me: Zain Syed - a 1st Class BSc Mathematics graduate
              (Warwick University) specialising in full stack development and
              with over 12 years digital agency experience. I can be contacted
              at{' '}
              {
                <a href="mailto:zain@flashmatics.co.uk">
                  zain@flashmatics.co.uk
                </a>
              }
            </p>
          </blockquote>
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};
