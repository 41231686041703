const gitURL = 'https://github.com/zain-ali-syed';

export const projects = [
  [
    {
      id: 9,
      title: 'React Music Catalogue',
      description:
        'A music catalogue which allows you to search for artists, albums, tracks and listen to track samples. Behind the scenes powered by the Deezer Music API',
      stack: ['react', 'redux', 'materialize'],
      demo: 'http://musicpreviewer-zainsyed.surge.sh/',
      github: 'https://github.com/zain-ali-syed/music_previewer.git',
      icon: 'images/goku5.png'
    },
    {
      id: 2,
      title: 'Storeo Ecommerce Site',
      description:
        'Storeo is an open-source, Javascript/SQL e-commerce package that allows businesses and developers to easily create and manage their e-commerce stores.',
      stack: ['react', 'mysql', 'node', 'materialize'],
      demo: '',
      github: `${gitURL}/STOREO-FE`,
      icon: 'images/antman.png'
    },
    {
      id: 4,
      title: 'React Quiz using Firebase',
      description:
        'This is a React Quiz App which uses the Firebase suite for authentication and as a database. Powered behind the scenes by the Open Trivia Database API.',
      stack: ['react', 'firebase', 'materialize'],
      demo: 'http://quiz-zainsyed.surge.sh',
      github: `${gitURL}/Quiz`,
      icon: 'images/goku2.png'
    },
    {
      id: 3,
      title: 'React With D3',
      description:
        'A barebones example showing how to setup React with D3. The data is from openweathermap.org. The chart shows the humidity level for the next 5 days of the chosen city.',
      stack: ['react', 'd3'],
      demo: 'http://zainsyed-reactd3.surge.sh/',
      github: `${gitURL}/react_d3.git`,
      icon: 'images/goku3.png'
    },
    {
      id: 6,
      title: 'Are You Smarter Flash Microsite',
      description:
        "This flash micro site was created to promote Sky One's Quiz show 'Are You Smarter' using Actionscript, PHP and mySQL.",
      stack: ['actionscript', 'phpmysql'],

      demo: 'http://www.flashmatics.co.uk/projects/areyousmarter/index.html',
      github: '',
      icon: 'images/SmarterLARGE.jpg'
    },
    {
      id: 1,
      title: 'FRAME A FRIEND',
      description:
        'This flash website was created to promote a new season of Prison Break.',

      stack: ['actionscript', 'php', 'javascript'],
      demo: 'http://www.flashmatics.co.uk/projects/frameafriend/index.html',
      github: '',
      icon: 'images/Prison-Break-prison-break-41361_1280_960.jpg'
    },
    {
      id: 1,
      title: 'Emirates HTML Banners',
      description:
        'HTML banners to promote Emirates Airline. DoubleClick as the adserver.',
      stack: ['html', 'css', 'javascript'],
      demo:
        'https://www.google.com/doubleclick/studio/externalpreview/#/ySyJfnMaSvWGeSQz4PEZBQ?creativeId=39930904',
      github: '',
      icon: 'images/emirates.png'
    },
    {
      id: 1,
      title: 'Booking.com HTML Banners',
      description:
        'HTML banners to promote Booking.com. DoubleClick as the adserver.',
      stack: ['html', 'css', 'javascript'],
      demo:
        'https://www.google.com/doubleclick/studio/externalpreview/#/_S6KfdUiTJC1V8K1Zitt5A?creativeId=43636287',
      github: '',
      icon: 'images/booking.png'
    }
  ],
  [
    {
      id: 7,
      title: 'React Guestbook using PHP and mySQL',
      description:
        'This is a React PHP mySQL Guestbook. The focus of this App is to show React working alongside PHP and mySQL.',
      stack: ['react', 'phpmysql', 'apache', 'materialize'],

      demo: `http://guestbookreactphp-zainsyed.surge.sh`,
      github: `${gitURL}/react_php_guestbook`,
      icon:
        'http://www.transparentpng.com/download/anime-boy/fRZVsA-anime-boy-clipart-hd.png'
    },
    {
      id: 5,
      title: 'Fullstack Todo App',
      description:
        'Yes! Yet another todo app. However it does tie a lot of different concepts together as a full stack app. This todo app implements React/Redux on the front-end. On the backend - MongoDB for the database, JWT for authentication (login and registration) and Express as the webserver on a Node backend.',
      stack: ['react', 'redux', 'node', 'mongo', 'materialize'],
      demo: 'http://todo-zainsyed.surge.sh/',
      github: `${gitURL}/todo`,
      icon: 'images/incredible.png'
    },
    {
      id: 8,
      title: 'React Responsive Image Gallery',
      description:
        'A responsive React Image Gallery using CSS flexbox and with image search functionality consuming the Pixabay API. ',
      stack: ['react'],
      demo: 'http://imagegallery-zainsyed.surge.sh/',
      github: `${gitURL}/image_gallery`,
      icon: 'images/chibi.png'
    },
    {
      id: 1,
      title: 'React GraphQL App',
      description:
        'App in progress - Using React, GraphQL & MongoDB, with Apollo Server 2 and Apollo Client.',

      stack: ['react', 'node', 'graphql', 'mongo', 'materialize'],
      demo: '',
      github: '',
      icon: 'images/daredevil.png'
    },
    {
      id: 1,
      title: 'Churchill Flash Microsite',
      description:
        'This Flash micro site quiz allow you the chance to win £2000 of travel vouchers from Churchill insurance.',

      stack: ['actionscript', 'phpmysql'],
      demo: 'http://www.flashmatics.co.uk/projects/churchill/index.html',
      github: '',
      icon: 'images/churchill.jpg'
    },
    {
      id: 1,
      title: '24',
      description:
        'An NMA award winning campaign to promote season 6 of the hit series 24. This campaign put the end-user at the centre of a 24 style mission using voice mail, text messages and email. More details about the project and its scope can be seen viewed below.',

      stack: ['actionscript', 'phpmysql', 'javascript'],
      demo: 'http://flashmatics.co.uk/portfolio/video.html',
      github: '',
      icon: 'images/24.jpg'
    },
    {
      id: 9,
      title: 'Fiat HTML Banners',
      description:
        'HTML banners for Fiat promoting their Fiat 500s range. Sizmek as the ad server.',
      stack: ['html', 'css', 'javascript'],
      demo: '/projects/fiat_html_banner',
      github: '',
      icon: 'images/fiat.png'
    },
    {
      id: 9,
      title: 'Adobe HTML Banners',
      description:
        'HTML banners to promote Adobe Marketing Cloud. Doubleclick as adserver.',
      stack: ['html', 'css', 'javascript'],
      demo: '/projects/fiat_html_banner',
      github: '',
      icon: 'images/adobe.png'
    }
  ]
];
