import React, { Component } from 'react';
import { Header, Content } from './Layout';

class App extends Component {
  render() {
    return (
      <>
        <Header />
        <Content />
      </>
    );
  }
}

export default App;
