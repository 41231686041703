import React, { Component } from 'react';
import Card from '../components/Card';
import { projects } from '../projects';

const index = 0;

class Content extends Component {
  renderProjects = column => {
    return column.map(({ title, description, stack, demo, github, icon }) => (
      <Card
        title={title}
        description={description}
        stack={stack}
        demo={demo}
        github={github}
        icon={icon}
      />
    ));
  };

  render() {
    return (
      <div className="container content">
        <div className="row">
          <div className="column">{this.renderProjects(projects[0])}</div>
          <div className="column">{this.renderProjects(projects[1])}</div>
        </div>
      </div>
    );
  }
}

export { Content };
